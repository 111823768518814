import { Injectable } from '@angular/core';
import {
  ActiveApprovesData,
  ApprovalValues,
  DestinoList,
  Destiny,
  EconomicGroup,
  Information,
  Observation,
  ValueDetails,
  Warranty,
} from '@models/ActOfApproval';
import { TipoDeActivosList } from '@models/AssetTypes';
import { IParametersTypeOperation } from '@models/Caracteristicas';
import { CreateLeaseDto } from '@models/CreateLeaseDto';
import { CreateOperationDto } from '@models/CreateOperationDto';
import { DataColumn } from '@models/DataColumn';
import { ICreateLease } from '@models/ICreateLease';
import { ICurrentRates, ParameterizedRates, PeriodicityReadjustment } from '@models/ICurrentRates';
import { IFinancialConditions } from '@models/IFinancialConditions';
import { ILegalFile } from '@models/ILegalFile.interface';
import { ILegalRepresentative } from '@models/ILegalRepresentative.interface';
import { ILoadData } from '@models/ILoadData';
import { IResponsibleBusiness } from '@models/IResponsibleBusiness.interfaces';
import { ISelectBox } from '@models/ISelectBox';
import { ProductosList } from '@models/Product';
import {
  ActiveApproves,
  Arrendatarios,
  ClientInformation,
  ICodeMora,
  IListProjectName,
  IRates,
  ListActiveMarkPage,
  ListUnitsPage,
  OperationByContract,
  RentOperation,
} from '@models/RentOperation';
import { RentOperationDto } from '@models/RentOperationDto';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RentOperationAction } from './actions';
import { State, fromRentOperation } from './selectors';

@Injectable({
  providedIn: 'root',
})
export class RentOperationFacadeService {
  constructor(private store: Store<State>) {}
  getReservedId(): Observable<string[] | null> {
    return this.store.select(fromRentOperation.getReservedId);
  }

  removePlateFromRedux({ plate }: { plate: string }): void {
    this.store.dispatch(RentOperationAction.removePlateFromRedux({ plate }));
  }

  setReserveId({ reservePlatesData }: any): void {
    this.store.dispatch(RentOperationAction.setReserveId({ reservePlatesData }));
  }
  findActivesByPlates({ monthTerm, plates }: any): void {
    this.store.dispatch(RentOperationAction.findActivesByPlates({ monthTerm, plates }));
  }

  getExistentDataColumn(): Observable<null | DataColumn[]> {
    return this.store.select(fromRentOperation.getExistentDataColumn);
  }

  getNonExistentDataColumn(): Observable<null | DataColumn[]> {
    return this.store.select(fromRentOperation.getNonExistentDataColumn);
  }

  findExistentDataColumns({ codeActiveType }: { codeActiveType: string }): void {
    this.store.dispatch(RentOperationAction.findExistentDataColumns({ codeActiveType }));
  }

  findNonExistentDataColumns({ codeActiveType }: { codeActiveType: string }): void {
    this.store.dispatch(RentOperationAction.findNonExistentDataColumns({ codeActiveType }));
  }

  getReservedPlates(): Observable<string[] | null | undefined> {
    return this.store.select(fromRentOperation.getReservedPlates);
  }
  findPlatesReserve({ plates }: { plates: string[] }): void {
    this.store.dispatch(RentOperationAction.findPlatesReserve({ plates }));
  }

  getListaFirmantes(): Observable<any> {
    return this.store.select(fromRentOperation.getListaFirmantes);
  }

  getCreateLeaseDto(): Observable<null | CreateLeaseDto> {
    return this.store.select(fromRentOperation.getCreateLeaseDto);
  }

  setCreateLeaseDto({ createLeaseDto }: { createLeaseDto: CreateLeaseDto }): void {
    this.store.dispatch(RentOperationAction.setCreateLeaseDto({ createLeaseDto }));
  }

  getCreateOperationDto(): Observable<CreateOperationDto | null> {
    return this.store.select(fromRentOperation.getCreateOperationDto);
  }

  setCreateOperationDto({ createOperationDto }: { createOperationDto: CreateOperationDto }): void {
    this.store.dispatch(RentOperationAction.setCreateOperationDto({ createOperationDto }));
  }

  getRentOperationDto(): Observable<null | RentOperationDto> {
    return this.store.select(fromRentOperation.getRentOperationDto);
  }

  setRentOperationDto(createRentOperationDto: RentOperationDto): void {
    this.store.dispatch(RentOperationAction.setRentOperationDto({ createRentOperationDto }));
  }

  setReservePlatesId({ reserveId }: { reserveId: string[] }): void {
    this.store.dispatch(RentOperationAction.setReservePlatesId({ reserveId }));
  }

  getReservePlatesId(): Observable<string[] | null> {
    return this.store.select(fromRentOperation.getReserveId);
  }

  setDestinyList({ destinyList }: { destinyList: DestinoList[] }): void {
    this.store.dispatch(RentOperationAction.setDestinyList({ destinyList }));
  }

  getDestinyList(): Observable<DestinoList[] | null> {
    return this.store.select(fromRentOperation.getDestinyList);
  }
  setActiveApproves({ activeApproves }: { activeApproves: ActiveApprovesData }): void {
    this.store.dispatch(RentOperationAction.setActiveApproves({ activeApproves }));
  }

  setApprovalValues({ approvalValuesData }: { approvalValuesData: any }): void {
    this.store.dispatch(RentOperationAction.setApprovalValues({ approvalValuesData }));
  }

  getSocietyValidity(): Observable<string | null | undefined> {
    return this.store.select(fromRentOperation.getSocietyValidity);
  }
  getSelectedAccountManager(): Observable<
    | {
        code: string;
        name: string;
      }
    | null
    | undefined
  > {
    return this.store.select(fromRentOperation.getSelectedAccountManager);
  }

  cleanStorage(): void {
    this.store.dispatch(RentOperationAction.resetState({}));
  }

  setCreateLease({ createLease }: { createLease: ICreateLease }): void {
    this.store.dispatch(
      RentOperationAction.setCreateLeaseAction({
        createLease,
      })
    );
  }

  getCreateLease(): Observable<ICreateLease | null> {
    return this.store.select(fromRentOperation.getCreateLease);
  }

  findOperationType(): void {
    this.store.dispatch(RentOperationAction.findOperationType({}));
  }

  getOperationType(): Observable<ISelectBox[] | null> {
    return this.store.select(fromRentOperation.getOperationType);
  }

  findOperationCharacteristic(page: number, size: number, filtro: string, estado: boolean): void {
    this.store.dispatch(
      RentOperationAction.findOperationCharacteristic({
        page,
        size,
        filtro,
        estado,
      })
    );
  }

  getOperationCharacteristic(): Observable<IParametersTypeOperation | null> {
    return this.store.select(fromRentOperation.getOperationCharacteristic);
  }

  getFundsByFilter(): Observable<ILoadData[] | null> {
    return this.store.select(fromRentOperation.getFundsByFilter);
  }

  setSelectedFundsByFilter({ code, description }: { code: string; description: string }): void {
    this.store.dispatch(RentOperationAction.setSelectedFundsByFilter({ code, description }));
  }

  findFundsByFilter({ code, description }: { code: string; description: string }): void {
    this.store.dispatch(RentOperationAction.findFundsByFilter({ code, description }));
  }

  getSelectedFundsByFilter(): Observable<{
    code: string;
    description: string;
  } | null> {
    return this.store.select(fromRentOperation.getSelectedFundsByFilter);
  }

  setSelectedChannelsByFilter({ code, description }: { code: string; description: string }): void {
    this.store.dispatch(RentOperationAction.setSelectedChannelsByFilter({ code, description }));
  }
  findChannelsByFilter({ code, description }: { code: string; description: string }): void {
    this.store.dispatch(RentOperationAction.findChannelsByFilter({ code, description }));
  }
  getChannelsByFilter(): Observable<ILoadData[] | null> {
    return this.store.select(fromRentOperation.getChannelsByFilter);
  }

  getSelectedChannelsByFilter(): Observable<{
    code: string;
    description: string;
  } | null> {
    return this.store.select(fromRentOperation.getSelectedChannelsByFilter);
  }

  getSelectedAssetsCondition(): Observable<{
    code: string;
    description: string;
  } | null> {
    return this.store.select(fromRentOperation.getSelectedAssetsCondition);
  }

  setSelectedAssetsCondition({ code, description }: { code: string; description: string }): void {
    this.store.dispatch(RentOperationAction.setSelectedAssetsCondition({ code, description }));
  }
  getAssetsCondition(): Observable<ILoadData[] | null> {
    return this.store.select(fromRentOperation.getAssetsCondition);
  }
  findAssetsCondition(): void {
    this.store.dispatch(RentOperationAction.findAssetsCondition({}));
  }

  getSelectedAssetsByFilter(): Observable<{
    code: string;
    description: string;
    permiteAnticipado: boolean;
  } | null> {
    return this.store.select(fromRentOperation.getSelectedAssetsByFilter);
  }
  setSelectedAssetsByFilter({
    code,
    description,
    permiteAnticipado,
  }: {
    code: string;
    description: string;
    permiteAnticipado: boolean;
  }): void {
    this.store.dispatch(
      RentOperationAction.setSelectedAssetsByFilter({ code, description, permiteAnticipado })
    );
  }

  getAssetsByFilter(): Observable<any> {
    return this.store.select(fromRentOperation.getAssetsByFilter);
  }

  findAssetsByFilter({ code, description }: { code: string; description: string }): void {
    this.store.dispatch(RentOperationAction.findAssetsByFilter({ code, description }));
  }

  setSelectedAccountManagerByFilter({
    code,
    description,
  }: {
    code: string;
    description: string;
  }): void {
    this.store.dispatch(
      RentOperationAction.setSelectedAccountManagerByFilter({
        code,
        description,
      })
    );
  }

  getSelectedAccountManagerByFilter(): Observable<{
    code: string;
    description: string;
  } | null> {
    return this.store.select(fromRentOperation.getSelectedAccountManagerByFilter);
  }

  getAccountManagerByFilter(): Observable<ILoadData[] | null> {
    return this.store.select(fromRentOperation.getAccountManagerByFilter);
  }

  findAccountManagerByFilter({ code, description }: { code: string; description: string }): void {
    this.store.dispatch(RentOperationAction.findAccountManagerByFilter({ code, description }));
  }

  getRespBusiness(): Observable<IResponsibleBusiness[] | null> {
    return this.store.select(fromRentOperation.geRespBusiness);
  }

  getSelectedRespBusiness(): Observable<{
    code: string;
    description: string;
  } | null> {
    return this.store.select(fromRentOperation.getSelectedRespBusiness);
  }

  setSelectedRespBusiness({ code, description }: { code: string; description: string }): void {
    this.store.dispatch(RentOperationAction.setSelectedRespBusiness({ code, description }));
  }

  findRespBusiness({ code, description }: { code: string; description: string }): void {
    this.store.dispatch(RentOperationAction.findRespBusiness({ code, description }));
  }
  getSelectedProduct(): Observable<{
    codeProduct: string;
    descripProduct: string;
  } | null> {
    return this.store.select(fromRentOperation.getSelectedProductByFilter);
  }
  setSelectedProduct({
    codeProduct,
    descripProduct,
  }: {
    codeProduct: string;
    descripProduct: string;
  }): void {
    this.store.dispatch(
      RentOperationAction.setSelectedProductByFilter({
        codeProduct,
        descripProduct,
      })
    );
  }

  findProductsByFilter({ code, description }: { code: string; description: string }): void {
    this.store.dispatch(RentOperationAction.findProductsByFilter({ code, description }));
  }

  getProductsByFilter(): Observable<ProductosList[] | null> {
    return this.store.select(fromRentOperation.getProducstByFilter);
  }

  getListCustomers(): Observable<ClientInformation[] | null> {
    return this.store.select(fromRentOperation.getListCustomers);
  }

  /**
   * postFilterListCustomer find the CustomerInformation list from the backend
   * @param {
   *  name: string,
   *  docClient: string,
   * }
   */
  postFilterListCustomer({ name, docClient }: { name: string; docClient: string }) {
    this.store.dispatch(RentOperationAction.postCustomerByFilter({ name, docClient }));
  }

  /**
   * getSelectedClient returns the { client, description } stored in the store
   * @returns Observable<{
   *    client: string;
   *    description: string;
   *  } | null>
   */
  getSelectedClient(): Observable<{
    client: string;
    description: string;
    tipoPersonaC: string;
  } | null> {
    return this.store.select(fromRentOperation.getSelectedClient);
  }

  /**
   * setSelectedClient facade to save the SelecteClient into the store
   * @param {
   *  client: string;
   *  description: string;
   * }
   */
  setSelectedClient({
    client,
    description,
    accountManager,
    tipoCartera,
    calificacionCliente,
    indicadorLlave,
    idBanco,
    tipoPersonaC,
    tipoDocCliente,
  }: {
    client: string;
    description: string;
    accountManager: {
      code: string;
      name: string;
    };
    tipoCartera: string;
    calificacionCliente: string;
    indicadorLlave: string;
    idBanco: string;
    tipoPersonaC: string;
    tipoDocCliente: string;
  }): void {
    this.store.dispatch(
      RentOperationAction.setSelectedClient({
        client,
        description,
        accountManager,
        tipoCartera,
        calificacionCliente,
        indicadorLlave,
        idBanco,
        tipoPersonaC,
        tipoDocCliente,
      })
    );
  }

  /**
   * setApprovalValuesList search the approval values list into the backend
   * then store it into the store
   * @param {
   *  docClient: string
   * }
   */
  setApprovalValuesList({
    docClient,
    page,
    size,
  }: {
    docClient: string;
    page: number;
    size: number;
  }): void {
    this.store.dispatch(RentOperationAction.findApprovalValuesList({ docClient, page, size }));
  }

  cleanApprovalValuesListData(): void {
    this.store.dispatch(
      RentOperationAction.setApprovalValuesList({
        approvalValuesList: {
          aprobacionesactivas: {
            page: { totalElements: 0, size: 0, number: 0 },
            content: [] as ActiveApproves['aprobacionesactivas']['content'],
          } as ActiveApproves['aprobacionesactivas'],
        } as ActiveApproves,
      })
    );
  }

  /**
   * setArrendatarios search the arrendatarios values list into the backend
   * then store it into the store
   * @param {
   *  noAprobacion: string,
   * noIdentificacio: string
   * }
   */
  setArrendatariosList({
    noAprobation,
    noIdentification,
  }: {
    noAprobation: string;
    noIdentification: string;
  }): void {
    this.store.dispatch(
      RentOperationAction.findArrendatarioData({
        noAprobation: noAprobation,
        noIdentification: noIdentification,
      })
    );
  }

  /**
   * getApprovalValuesList
   * @returns Observable<ActiveApproves[] | null>
   */
  getApprovalValuesList(): Observable<ActiveApproves | null> {
    return this.store.select(fromRentOperation.getApprovalValuesList);
  }

  /**
   * getArrendatarios
   * @returns Observable<Arrendatarios | null>
   */
  getArrendatariosList(): Observable<Arrendatarios | null> {
    return this.store.select(fromRentOperation.getArrendatariosList);
  }

  /**
   * findRentOperationValues find the rent operation values into the backend
   * @param {
   *  approvalValue: string
   * }
   */
  findRentOperationValues({ approvalValue }: { approvalValue: string }): void {
    this.store.dispatch(RentOperationAction.findRentOperationValues({ approvalValue }));
  }

  /**
   * getRentOperationValues get the rent operation values from the store
   * @returns Observable<RentOperation | null>
   */
  getRentOperationValues(): Observable<RentOperation | null> {
    return this.store.select(fromRentOperation.getRentOperationValues);
  }

  setRentOperationValues({ rentOperationValues }: { rentOperationValues: RentOperation }): void {
    this.store.dispatch(
      RentOperationAction.setRentOperationValues({
        rentOperationValues,
      })
    );
  }

  getError(): Observable<any> {
    return this.store.select(fromRentOperation.getError);
  }

  resetErrorFlags(): void {
    this.store.dispatch(RentOperationAction.onResetFlagsError());
  }

  /**
   * getLegalFileData get the legal file data from the store
   * @returns Observable<ILegalFile | null>
   */
  getLegalFileData(): Observable<ILegalFile | undefined | null> {
    return this.store.select(fromRentOperation.getLegalFileData);
  }

  /**
   * findLegalFileData
   * @param  {
   *  docNumber: string
   * }
   */
  findLegalFileData({ docNumber }: { docNumber: string }): void {
    this.store.dispatch(RentOperationAction.findLegalFileData({ docNumber }));
  }

  /**
   * getLegalRepresentativeData get the legal representativa data from the store
   * @returns Observable<ILegalRepresentative | null>
   */
  getLegalRepresentativeData(): Observable<ILegalRepresentative | null> {
    return this.store.select(fromRentOperation.getLegalRepresentativeData);
  }

  /**
   * findLegalRepresentativeData get the legal representative data from
   * the backend then save it into the store
   * @param {
   *  docNumber: string
   * }
   */
  findLegalRepresentativeData({
    docNumber,
    page,
    size,
  }: {
    docNumber: string;
    page: number;
    size: number;
  }): void {
    this.store.dispatch(RentOperationAction.findLegalRepresentativeData({ docNumber, page, size }));
  }

  /**
   * getApprovalValuesData get the approval values from the store
   * @returns Observable<ApprovalValues | null>
   */
  getApprovalValuesData(): Observable<ApprovalValues | null> {
    return this.store.select(fromRentOperation.getApprovalValuesData);
  }

  getActiveApproves(): Observable<ActiveApprovesData | null> {
    return this.store.select(fromRentOperation.getActiveApproves);
  }

  /**
   * findApprovalValuesData get the approval values from the backend
   * then save it into the store
   * @param {
   *  approvalNumber: string
   * }
   */
  findApprovalValuesData({
    approvalNumber,
    page,
    size,
  }: {
    approvalNumber: string;
    page: number;
    size: number;
  }): void {
    this.store.dispatch(RentOperationAction.findApprovalValuesData({ approvalNumber, page, size }));
  }

  /**
   * getDetailValuesData get the detail values from the store
   * @returns Observable<ValueDetails | null>
   */
  getDetailValuesData(): Observable<ValueDetails | null> {
    return this.store.select(fromRentOperation.getDetailValuesData);
  }

  /**
   * findDetailValuesData get the DetailValues from the backend then
   * save it into the store
   * @param {
   *   approvalNumber: string
   * }
   */
  findDetailValuesData({
    approvalNumber,
    numOperacion,
    numFactura,
    page,
    size,
  }: {
    approvalNumber: string;
    numOperacion: string | '';
    numFactura: string | '';
    page: number;
    size: number;
  }): void {
    this.store.dispatch(
      RentOperationAction.findDetailValuesData({
        approvalNumber,
        numOperacion,
        numFactura,
        page,
        size,
      })
    );
  }

  /**
   * getFinalDestinationsData get the finalDestination from the store
   * @returns Observable<Destiny | null>
   */
  getFinalDestinationsData(): Observable<Destiny | null> {
    return this.store.select(fromRentOperation.getDestinationsData);
  }

  /**
   * findDestinationsData get the destinations from the backend then
   * save it into the store
   * @param {
   *  approvalNumber: string
   * }
   */
  findDestinationsData({
    approvalNumber,
    page,
    size,
  }: {
    approvalNumber: string;
    page: number;
    size: number | string;
  }): void {
    this.store.dispatch(RentOperationAction.findDestinationData({ approvalNumber, page, size }));
  }

  /**
   * getWarrantyData get the warranty from the store
   * @returns Observable<Warranty | null>
   */
  getWarrantyData(): Observable<Warranty | null> {
    return this.store.select(fromRentOperation.getWarrantyData);
  }

  /**
   * findWarrantyData get the warranty from the backend the save it
   * into the store
   * @param {
   *  approvalNumber: string
   * }
   */
  findWarrantyData({
    approvalNumber,
    page,
    size,
  }: {
    approvalNumber: string;
    page: number;
    size: number;
  }): void {
    this.store.dispatch(RentOperationAction.findWarrantyData({ approvalNumber, page, size }));
  }

  /**
   * getObservationsData get the observation data from the store
   * @returns Observable<Observation | null>
   */
  getObservationsData(): Observable<Observation | null> {
    return this.store.select(fromRentOperation.getObservationsData);
  }

  /**
   * findObservationsData get the observations from the backend then
   * save it into the store
   * @param {
   *  approvalNumber: string
   * }
   */
  findObservationsData({ approvalNumber }: { approvalNumber: string }): void {
    this.store.dispatch(RentOperationAction.findObservationData({ approvalNumber }));
  }

  /**
   * getEconomicGroupData get the economic group from the store
   * @returns Observable<EconomicGroup | null>
   */
  getEconomicGroupData(): Observable<EconomicGroup | null> {
    return this.store.select(fromRentOperation.getEconomicGroupData);
  }

  /**
   * findEconomicGroupData get the economic group then save it into
   * the store
   * @param {
   *  docClient: string
   * }
   */
  findEconomicGroupData({ docClient }: { docClient: string }): void {
    this.store.dispatch(RentOperationAction.findEconomicGroupData({ docClient }));
  }

  /**
   * findRatesList find the Rates=tasas list from the backend
   */
  findRatesList(): void {
    this.store.dispatch(RentOperationAction.findRates({}));
  }
  /**
   * getRates obtiene la tasa seleccionada
   */
  getRates(): Observable<IRates[] | null> {
    return this.store.select(fromRentOperation.getRatesSelector);
  }

  /**
   * setSelectedRate facade to save the SelecteRate into the store
   */
  setSelectedRate(selectedRate: IRates): void {
    this.store.dispatch(
      RentOperationAction.setSelectedRate({
        selectedRate,
      })
    );
  }
  /**
   * getSelectedRate
   */
  getSelectedRate(): Observable<IRates | null> {
    return this.store.select(fromRentOperation.getSelectedRateSelector);
  }

  /**SERVICIO CODIGO MORA */
  findCodeMoraByFilter({ code, description }: { code: string; description: string }): void {
    this.store.dispatch(RentOperationAction.findCodeMoraByFilter({ code, description }));
  }

  getCodeMora(): Observable<ICodeMora[] | null> {
    return this.store.select(fromRentOperation.getCodeMoraSelector);
  }

  /**
   * setSelectedCodeMora facade to save the SelectedCodeMora into the store
   * @param {
   *  code: string;
   *  description: string;
   * }
   */
  setSelectedCodeMora({ code, description }: { code: string; description: string }): void {
    this.store.dispatch(RentOperationAction.setSelectedCodeMora({ code, description }));
  }

  /**
   * getSelectedCodeMora returns the { code, description } stored in the store
   * @returns Observable<{
   *    code: string;
   *    description: string;
   *  } | null>
   */
  getSelectedCodeMora(): Observable<{
    code: string;
    description: string;
  } | null> {
    return this.store.select(fromRentOperation.getSelectedCodeMoraSelector);
  }

  /**
   * getInfActApprovalData get the approval values from the store
   * @returns Observable<Information | null>
   */
  getInfActApprovalData(): Observable<Information | null> {
    return this.store.select(fromRentOperation.getInfActApprovalData);
  }

  /**
   * findInfActApprovalData get the approval values from the backend
   * then save it into the store
   * @param {
   *  approvalNumber: string
   * }
   */
  findInfActApprovalData({ approvalNumber }: { approvalNumber: string }): void {
    this.store.dispatch(RentOperationAction.findInfActApprovalData({ approvalNumber }));
  }

  /**
   * getUrlOrigen get the urlOrigen from the store
   * @returns Observable<string | null>
   */
  getUrlOrigen(): Observable<string | null> {
    return this.store.select(fromRentOperation.getUrlOrigen);
  }

  /**
   * findUrlOrigen get the urlOrigen the store
   * @param {
   *  origen: string
   * }
   */
  findUrlOrigen({ origen }: { origen: string }): void {
    this.store.dispatch(RentOperationAction.findUrlOrigen({ origen }));
  }

  /**
   * getProjectNameList get the projects name values from the store
   * @returns Observable<IListProjectName | null>
   */
  getProjectNameList(): Observable<IListProjectName | null> {
    return this.store.select(fromRentOperation.getNameProjectList);
  }

  /**
   * findProjectNameList get the ProjectName values from the backend
   * then save it into the store
   */
  findProjectNameList(): void {
    this.store.dispatch(RentOperationAction.findProjectNameList({}));
  }

  /**
   * getUnitsActiveList get the UnitsActiveLis name values from the store
   * @returns Observable<ListUnitsPage | null>
   */
  getUnitsActiveList(): Observable<ListUnitsPage | null> {
    return this.store.select(fromRentOperation.getUnitsActiveList);
  }

  /**
   * findUnitsActiveList get the UnitsActiveList values from the backend
   * then save it into the store
   * @param {
   * nomProject: string;
   * tipoActivo: string
   * page:number;
   * size:number;
   * }
   */
  findUnitsActiveList(nomProject: string, tipoActivo: string, page: number, size: number): void {
    this.store.dispatch(
      RentOperationAction.findUnitsActiveList({
        nomProject,
        tipoActivo,
        page,
        size,
      })
    );
  }

  getActivesMarkList(): Observable<ListActiveMarkPage | null> {
    return this.store.select(fromRentOperation.getActivesMarkList);
  }

  findActivesMarkList(
    codTipoActivo: string,
    codGrupo: string,
    codSubGrupo: string,
    activoMarca: string,
    page: number,
    size: number
  ): void {
    this.store.dispatch(
      RentOperationAction.findActivesMarkList({
        codTipoActivo,
        codGrupo,
        codSubGrupo,
        activoMarca,
        page,
        size,
      })
    );
  }

  findOpByContractNumber({ contractNumber }: { contractNumber: string }): void {
    this.store.dispatch(RentOperationAction.findOpByContractNumber({ contractNumber }));
  }

  getOpByContractNumber(): Observable<OperationByContract | null> {
    return this.store.select(fromRentOperation.getOpByContractNumber);
  }

  resetOpByContractNumberFlags(): void {
    this.store.dispatch(RentOperationAction.onResetFlagsOpByContractNumber());
  }

  findCurrentRates(): void {
    this.store.dispatch(RentOperationAction.findCurrentRates());
  }

  getCurrentRates(): Observable<ICurrentRates | null> {
    return this.store.select(fromRentOperation.getCurrentRates);
  }
  resetCurrentRatesFlags(): void {
    this.store.dispatch(RentOperationAction.onResetFlagsCurrentRates());
  }

  /* FnlConds = Condiciones financieras*/
  setSelectedDataFnlConds({
    dataFinancialConditions,
  }: {
    dataFinancialConditions: IFinancialConditions;
  }) {
    this.store.dispatch(RentOperationAction.setSelectedDataFnlConds({ dataFinancialConditions }));
  }

  getSelectedDataFnlConds(): Observable<IFinancialConditions | null> {
    return this.store.select(fromRentOperation.getSelectedDataFnlConds);
  }

  setDataAssetTypesSelected({
    dataAssetTypesSelected,
  }: {
    dataAssetTypesSelected: TipoDeActivosList[];
  }) {
    this.store.dispatch(RentOperationAction.setSelectedAssetsType({ dataAssetTypesSelected }));
  }

  resetDataAssetTypesSelected(): void {
    this.store.dispatch(RentOperationAction.resetDataAssetTypesSelected());
  }

  resetProductsByFilter(): void {
    this.store.dispatch(RentOperationAction.resetProductsByFilter());
  }

  setSelectedCurrentRate({ selectedCurrentRate }: { selectedCurrentRate: ParameterizedRates }) {
    this.store.dispatch(RentOperationAction.setSelectedCurrentRate({ selectedCurrentRate }));
  }

  getSelectedCurrentRate(): Observable<ParameterizedRates | null> {
    return this.store.select(fromRentOperation.getSelectedCurrentRate);
  }

  setSelectedRadjPeriodicity({
    selectedRadjPeriodicity,
  }: {
    selectedRadjPeriodicity: PeriodicityReadjustment;
  }) {
    this.store.dispatch(
      RentOperationAction.setSelectedRadjPeriodicity({ selectedRadjPeriodicity })
    );
  }

  getSelectedRadjPeriodicity(): Observable<PeriodicityReadjustment | null> {
    return this.store.select(fromRentOperation.getSelectedRadjPeriodicity);
  }

  setSelectedPaymentPeriodicity({
    selectedPaymentPeriodicity,
  }: {
    selectedPaymentPeriodicity: ISelectBox;
  }) {
    this.store.dispatch(
      RentOperationAction.setSelectedPaymentPeriodicity({ selectedPaymentPeriodicity })
    );
  }

  getSelectedPaymentPeriodicity(): Observable<ISelectBox | null> {
    return this.store.select(fromRentOperation.getSelectedPaymentPeriodicity);
  }

  setSelectReadjFormula({ selectedReadjFormula }: { selectedReadjFormula: ISelectBox }) {
    this.store.dispatch(RentOperationAction.setSelectedReadjFormula({ selectedReadjFormula }));
  }

  getSelectedReadjFormula(): Observable<ISelectBox | null> {
    return this.store.select(fromRentOperation.getSelectedReadjFormula);
  }

  setDataAssetToReserve({ dataAssetToReserve }: { dataAssetToReserve: any[] }) {
    this.store.dispatch(RentOperationAction.setSelectedAssetToReserve({ dataAssetToReserve }));
  }

  resetDataAssetToReserve(): void {
    this.store.dispatch(RentOperationAction.resetDataAssetToReserve());
  }
}
