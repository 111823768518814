<bc-modal
  #modal
  [title]="title"
  [typeIcon]="typeIcon"
  [customWidth]="customWidth"
  [titleAlign]="titleAlign"
  [isCancellable]="isCancellable"
  [outerCloseTap]="outerCloseTap"
  [buttonAlignment]="buttonAlignment"
  [textBtn]="textBtn"
  [textBtnLeft]="textBtnLeft"
  (stateClick)="onPressButton($event)"
  (closeModalEvent)="onPressButtonEquis($event)"
  [displayButton]="displayButton"
  [hideCloseButton]="hideCloseButton"
  [buttonAlignment]="'horizontal'"
  [closedModalEsc]="closedModalEsc"
  [ngClass]="{ 'bcs-container-modal_minHeight_50vh': showModalFilter }"
>
  <div illustration>
    <bc-icon
      aria-hidden="true"
      [name]="customIconName"
      aria-label="prueba"
      role="img"
      width="150px"
      class="bc-xl"
    ></bc-icon>
  </div>
  <div modalContent class="text-center">
    <form
      *ngIf="showModalFilter"
      [formGroup]="formGroupModalFilter"
      autocomplete="off"
      class="bcs-container-modal_v2_body_form"
    >
      <section class="bc-row bc-d-flex bc-justify-content-center">
        <div
          *ngIf="showTexBoxFilter1"
          [ngClass]="{ 'bc-col-5': showTexBoxFilter2, 'bc-col-10': !showTexBoxFilter2 }"
        >
          <bcs-text-box
            Id="labelTextBox1"
            [Label]="labelTextBox1"
            class="bc-text-start"
            OnlylettersAndNumbers
            [InputFormControl]="InputFormControl1"
          ></bcs-text-box>
        </div>
        <div *ngIf="showTexBoxFilter2" [ngClass]="{ 'bc-col-5': showTexBoxFilter2 }">
          <bcs-text-box
            Id="labelTextBox2"
            [Label]="labelTextBox2"
            class="bc-text-start"
            OnlylettersAndNumbers
            [InputFormControl]="InputFormControl2"
          ></bcs-text-box>
        </div>
        <div class="bc-col-1 bc-d-flex bc-align-items-center">
          <bc-icon class="bcs-pointer bc-font-weight-bold" (click)="filter()">search</bc-icon>
        </div>
      </section>
    </form>
    <ng-content></ng-content>
  </div>
</bc-modal>
