import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IParametersTypeOperation,
  IResponseCharacCreateUpdate,
} from 'src/app/core/models/Caracteristicas';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiHelperService } from '@services/apihelper/api-helper.service';
import { backend } from 'src/paths/development';
import { Numbers } from '../../core/constants/numbers';
import { CaracteristicasList } from '@models/Caracteristicas';
import { ICreateUpdateParam } from '@models/ISharedResponse';

@Injectable({ providedIn: 'root' })
export class OperationService {
  constructor(private http: ApiHelperService) {}

  /**
   * Get an array of operation
   * @param page: Number of the page to show in a view
   * @param size: Size of elements to show in a view
   * @returns Observable<Operation[]>
   */
  getAllOperations(
    page?: number,
    size?: number | '',
    filtro?: string,
    estado?: boolean
  ): Observable<IParametersTypeOperation> {
    const pageRequest = page ?? Numbers._0;
    const sizeRequest = size ?? Numbers._5;
    const queryParams = `?page=${pageRequest}&size=${sizeRequest}&filtro=${filtro}&estado=${estado}`;
    return this.http.get(`${backend.operation.getAll}${queryParams}`).pipe(
      map((response: any) => response),
      map((response) => response as IParametersTypeOperation)
    );
  }

  // /**
  //  * Create an operation
  //  * @param code: Code of the new operation
  //  * @param name: Name of the new operation
  //  * @returns Observable<number>
  //  */
  // createOperation(
  //   code: string,
  //   name: string,
  //   active: boolean
  // ): Observable<CaracteristicasList | undefined> {
  //   return this.http
  //     .post<any>(
  //       backend.operation.create,
  //       {
  //         codigo: code,
  //         nombre: name,
  //         activo: active,
  //       },
  //       undefined,
  //       {
  //         observe: 'response',
  //       }
  //     )
  //     .pipe(
  //       map((res: HttpResponse<any>) => {
  //         if (res.status === Numbers._201) {
  //           return res.body.caracteristicas as CaracteristicasList;
  //         }
  //         throw throwError(
  //           new Error(res.body.caracteristicas ?? res.body.Message ?? 'Error al crear la operación')
  //         );
  //       })
  //     );
  // }

  // /**
  //  * Update an existent operation
  //  * @param {id, code, name, status}: New data of an existent operation and operation id
  //  * @returns Observable<number>
  //  */
  // updateOperation({ id, code, name, status }: { [key: string]: any }): Observable<number> {
  //   return this.http
  //     .put<any>(
  //       backend.operation.update,
  //       {
  //         id,
  //         codigo: code,
  //         nombre: name,
  //         activo: status,
  //       },
  //       undefined,
  //       {
  //         observe: 'response',
  //       }
  //     )
  //     .pipe(map((res: HttpResponse<any>) => res.status));
  // }

  /**
   * Crear parametro
   * @param body: ICreateUpdateParam
   * @returns Observable<IResponseCharacCreateUpdate>
   * Ope = operacion
   */
  createCharacteristicsOpe({
    body,
  }: {
    body: ICreateUpdateParam;
  }): Observable<IResponseCharacCreateUpdate> {
    return this.http.post(`${backend.operation.create}`, body);
  }

  /**
   * Actualizar parametro
   * @param body: ICreateUpdateParam
   * @returns Observable<IResponseCharacCreateUpdate>
   * Ope = operacion
   */
  updateCharacteristicsOpe({
    body,
  }: {
    body: ICreateUpdateParam;
  }): Observable<IResponseCharacCreateUpdate> {
    return this.http.put(`${backend.operation.update}`, body);
  }

  /**
   * Search an existent operation by operation code
   * @param code: A code operation to search
   * @returns Observable<CaracteristicasList>
   */
  searchByCoodeOperation(code: string): Observable<CaracteristicasList> {
    return this.http
      .get(`${backend.operation.getByCode}/${code}`)
      .pipe(map((res: any) => res as CaracteristicasList));
  }

  /**
   * Deactivate an existent operation by operation code
   * @param code: A code operation to deactivate
   * @returns Observable<number>
   */
  deactivateOperation(code: string): Observable<number> {
    return this.http
      .put<any>(`${backend.operation.deactivate}/${code}`, {}, undefined, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res.status));
  }

  /**
   * Activate an existent operation by operation code
   * @param code: A code operation to activate
   * @returns Observable<number>
   */
  activateOperation(code: string): Observable<number> {
    return this.http
      .put<any>(`${backend.operation.activate}/${code}`, {}, undefined, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res.status));
  }
}
