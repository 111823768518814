<bc-form-field>
  <em *ngIf="Icon != ''">{{ Icon }}</em>
  <ng-container *ngIf="inputFormControlName">
    <input
      *ngIf="Type !== 'format' && Type !== 'money'"
      [id]="Id"
      bc-input
      [type]="Type"
      [formControlName]="inputFormControlName"
      autocomplete="off"
      (input)="onInput()"
      [ngClass]="{ 'bc-input-error': isError }"
      [readonly]="readonly"
    />
    <input
      *ngIf="Type === 'format'"
      [id]="Id"
      bc-input
      [type]="Type"
      currencyMask
      (input)="onInput()"
      [options]="{
        suffix: '%',
        prefix: '',
        decimal: '.',
        precision: 5,
        max: 100,
        align: 'left',
        suffixSpace: true
      }"
      [formControlName]="inputFormControlName"
      autocomplete="off"
      placeholder=""
      [ngClass]="{ 'bc-input-error': isError }"
      [readonly]="readonly"
    />
    <input
      *ngIf="Type === 'money'"
      [id]="Id"
      bc-input
      [type]="Type"
      currencyMask
      [options]="
        formatPercentage
          ? optionCurrencyMask
          : { suffix: '', prefix: '$', align: 'left', decimal: '', thousands: '.', precision: 0 }
      "
      [formControlName]="inputFormControlName"
      autocomplete="off"
      (input)="onInput()"
      [ngClass]="{ 'bc-input-error': isError }"
      [readonly]="readonly"
    />
  </ng-container>
  <ng-container *ngIf="!inputFormControlName && !space">
    <input
      *ngIf="Type !== 'format' && Type !== 'money'"
      [id]="Id"
      bc-input
      [type]="Type"
      [formControl]="InputFormControl"
      autocomplete="off"
      (input)="onInput()"
      [ngClass]="{ 'bc-input-error': isError }"
      [readonly]="readonly"
    />
    <input
      *ngIf="Type === 'format'"
      [id]="Id"
      bc-input
      [type]="Type"
      currencyMask
      (input)="onInput()"
      [options]="{
        suffix: '%',
        prefix: '',
        decimal: '.',
        precision: 5,
        max: 100,
        align: 'left',
        suffixSpace: true
      }"
      [formControl]="InputFormControl"
      autocomplete="off"
      [ngClass]="{ 'bc-input-error': isError }"
      [required]="isRequired"
      [readonly]="readonly"
    />
    <input
      *ngIf="Type === 'money'"
      [id]="Id"
      bc-input
      [type]="Type"
      currencyMask
      [options]="
        formatPercentage
          ? optionCurrencyMask
          : { suffix: '', prefix: '$', align: 'left', decimal: '', thousands: '.', precision: 0 }
      "
      [formControl]="InputFormControl"
      autocomplete="off"
      (input)="onInput()"
      [ngClass]="{ 'bc-input-error': isError }"
      [ngClass]="{ 'bcs-negative-value': negativeValue }"
      [readonly]="readonly"
    />
  </ng-container>
  <em class="bcs-pointer">{{ IconRight }}</em>
  <label [for]="Id">{{ Label }}</label>
  <span *ngIf="!isError">{{ HelpText }}</span>
  <span *ngIf="isError" class="bcs-input-error">
    {{ errorText }}
  </span>
</bc-form-field>
