import { Component, Input, ViewChild, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BcModalComponent } from '@bancolombia/design-system-web/bc-modal';

export type IModalTypeIcon = 'error' | 'success' | 'warning' | 'info' | 'default';
export type IModalCustomWidth = 'small' | 'medium' | 'large';

@Component({
  selector: 'app-bcs-modal',
  templateUrl: './bcs-modal.component.html',
  styleUrls: ['./bcs-modal.component.scss'],
})
export class BcsModalComponent implements AfterViewInit {
  @ViewChild(BcModalComponent) modal!: BcModalComponent;
  @Output() stateClick: EventEmitter<number> = new EventEmitter<number>();
  @Output() closeModalEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleFilter = new EventEmitter();
  @Input() customWidth: IModalCustomWidth = 'small';
  @Input() title: string = 'Title';
  @Input() textBtn: string = 'Sí';
  @Input() textBtnLeft: string = 'No';
  @Input() content: string = 'Content';
  @Input() typeIcon: IModalTypeIcon = 'info';
  @Input() customIconName = 'info';
  @Input() isCancellable: boolean = true;
  @Input() outerCloseTap = true;
  @Input() displayButton = true;
  @Input() disableIcon = false;
  @Input() hideCloseButton = false;
  @Input() closedModalEsc = false;
  @Input() showModalFilter = false;
  buttonAlignment: 'horizontal' | 'vertical' = 'horizontal';
  titleAlign: 'center' | 'left' = 'center';
  // Filtro
  @Input() showTexBoxFilter1 = false;
  @Input() showTexBoxFilter2 = false;
  @Input() labelTextBox1 = '';
  @Input() labelTextBox2 = '';
  @Input() formGroupModalFilter!: UntypedFormGroup;
  @Input() InputFormControl1 = new UntypedFormControl('');
  @Input() InputFormControl2 = new UntypedFormControl('');
  constructor() {
    // BcsModalComponent constructor
  }

  ngAfterViewInit(): void {
    if (this.disableIcon) {
      const icon = document.querySelector(
        'bc-modal > article > section > section > div'
      ) as HTMLElement;
      if (icon) {
        icon.style.display = 'none';
      }
    }
  }

  onPressButton(value: number) {
    this.stateClick.emit(value);
  }

  onPressButtonEquis(value: any) {
    this.closeModalEvent.emit(value);
  }

  openModal() {
    this.modal?.openModal();
  }

  closeModal() {
    this.modal?.shutDown();
  }

  shutDown() {
    this.modal?.shutDown();
  }

  filter(): void {
    this.handleFilter.emit();
  }
}
