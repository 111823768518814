import {
  ActiveApprovesData,
  ApprovalValues,
  DestinoList,
  Destiny,
  EconomicGroup,
  Information,
  Observation,
  ValueDetails,
  Warranty,
} from '@models/ActOfApproval';
import { TipoDeActivosList } from '@models/AssetTypes';
import { IParametersTypeOperation } from '@models/Caracteristicas';
import { CreateLeaseDto } from '@models/CreateLeaseDto';
import { CreateOperationDto } from '@models/CreateOperationDto';
import { DataColumn } from '@models/DataColumn';
import { ICreateLease } from '@models/ICreateLease';
import { ICurrentRates, ParameterizedRates, PeriodicityReadjustment } from '@models/ICurrentRates';
import { IFinancialConditions } from '@models/IFinancialConditions';
import { ILegalFile } from '@models/ILegalFile.interface';
import { ILegalRepresentative } from '@models/ILegalRepresentative.interface';
import { ILoadData } from '@models/ILoadData';
import { IResponsibleBusiness } from '@models/IResponsibleBusiness.interfaces';
import { ISelectBox } from '@models/ISelectBox';
import { ProductosList } from '@models/Product';
import {
  ActiveApproves,
  Arrendatarios,
  ClientInformation,
  ICodeMora,
  IListProjectName,
  IRates,
  ListActiveMarkPage,
  ListUnitsPage,
  OperationByContract,
  RentOperation,
} from '@models/RentOperation';
import { RentOperationDto } from '@models/RentOperationDto';
import { ReservePlates, ReservePlatesData } from '@models/ReservePlates';
import { Action, createReducer, on } from '@ngrx/store';
import { RentOperationAction } from './actions';

interface RentOperationState {
  customersList: ClientInformation[] | null;
  selectedClient: {
    client: string;
    description: string;
    accountManager: { code: string; name: string } | null;
    tipoCartera: string;
    calificacionCliente: string;
    indicadorLlave: string;
    idBanco: string;
    tipoPersonaC: string;
    tipoDocCliente: string;
  } | null;
  approvalValuesList: ActiveApproves | null;
  rentOperationValues: RentOperation | null;
  legalFile: ILegalFile | undefined | null;
  legalRepresentative: ILegalRepresentative | null;
  infActApproval: Information | null;
  approvalValuesData: ApprovalValues | null;
  activeApproves: ActiveApprovesData | null;
  detailValues: ValueDetails | null;
  destinations: Destiny | null;
  warranty: Warranty | null;
  observations: Observation | null;
  economicGroup: EconomicGroup | null;
  productsByFilterData: ProductosList[] | null;
  selectedProductByFilter: {
    codeProduct: string;
    descripProduct: string;
  } | null;
  respBusiness: IResponsibleBusiness[] | null;
  error: any;
  isLoading: boolean;
  found: { search: boolean; found: boolean };
  selectedRespBusiness: { code: string; description: string } | null;
  accountManagerByFilter: ILoadData[] | null;
  selectedAccountManagerByFilter: { code: string; description: string } | null;
  assetsByFilter: ILoadData[] | null;
  selectedAssetsByFilter: { code: string; description: string; permiteAnticipado: boolean } | null;
  assetsCondition: ILoadData[] | null;
  selectedAssetsCondition: { code: string; description: string } | null;
  selectedChannelsByFilter: { code: string; description: string } | null;
  channelsByFilter: ILoadData[] | null;
  fundsByFilter: ILoadData[] | null;
  selectedFundsByFilter: { code: string; description: string } | null;
  operationType: ISelectBox[] | null;
  operationCharacteristic: IParametersTypeOperation | null;
  ratesList: IRates[] | null;
  selectedRate: IRates | null;
  codeMoraByFilter: ICodeMora[] | null;
  selectedCodeMoraByFilter: { code: string; description: string } | null;
  createLease: ICreateLease | null;
  arrendatarios: Arrendatarios | null;
  destinyList: DestinoList[] | null;
  projectNameList: IListProjectName | null;
  unitsActiveList: ListUnitsPage | null;
  activesMarkList: ListActiveMarkPage | null;
  opByContractNumber: OperationByContract | null;
  reserveId: string[] | null;
  currentRates: ICurrentRates | null;
  dataFinancialConditions: IFinancialConditions | null;
  createRentOperationDto: RentOperationDto | null;
  createOperationDto: CreateOperationDto | null;
  createLeaseDto: CreateLeaseDto | null;
  reservePlates: ReservePlates[] | null;
  findExistentDataColumns: string | null;
  findNonExistentDataColumns: string | null;
  existentDataColumn: DataColumn[] | null;
  nonExistentDataColumn: DataColumn[] | null;
  dataAssetTypesSelected: TipoDeActivosList[] | null;
  selectedCurrentRate: ParameterizedRates | null;
  selectedRadjPeriodicity: PeriodicityReadjustment | null;
  selectedPaymentPeriodicity: ISelectBox | null;
  selectedReadjFormula: ISelectBox | null;
  dataAssetToReserve: any[] | null;
  urlOrigen: string | null;
}

const initialStateGenerator: () => RentOperationState = () =>
  JSON.parse(
    '{"customersList": null,"selectedClient": null,"detailValues": null,"legalFile": null,"destinations": null, "infActApproval":null,"productsByFilterData": null,"warranty": null,"observations": null,"selectedProductByFilter": null,"respBusiness": null,"economicGroup": null,"rentOperationValues": null,"approvalValuesList": null,"legalRepresentative": null,"approvalValuesData": null,"error": null,"isLoading": false,"found": { "search": false, "found": false },"selectedRespBusiness": null,"accountManagerByFilter": null,"selectedAccountManagerByFilter": null,"assetsByFilter": null,"selectedAssetsByFilter": null,"assetsCondition": null,"selectedAssetsCondition": null,"selectedChannelsByFilter": null,"channelsByFilter": null,"fundsByFilter": null,"selectedFundsByFilter": null,"operationType": null,"operationCharacteristic": null,"ratesList": null,"selectedRate": null,"calCodeByFilter": null,"selectedCalCodeByFilter": null,"codeMoraByFilter": null,"selectedCodeMoraByFilter": null,"createLease": null, "codeTasaByFilter": null, "selectedCodeTasa": null, "activeApproves": null, "arrendatarios": null, "projectNameList": null, "unitsActiveList": null, "activesMarkList": null, "destinyList": null, "reserveId": null,"dataCreateLease": null, "opByContractNumber": null, "currentRates": null, "dataFinancialConditions": null, "createRentOperationDto": null, "createOperationDto": null, "createLeaseDto": null, "reservePlates": null, "findExistentDataColumns": null, "findNonExistentDataColumns": null,"dataAssetTypesSelected": null, "selectedCurrentRate": null, "selectedRadjPeriodicity": null, "selectedPaymentPeriodicity": null, "selectedReadjFormula": null, "dataAssetToReserve": null, "urlOrigen": null}'
  );

export const initialState: RentOperationState = initialStateGenerator();

const featureReducer = createReducer(
  initialState,
  on(RentOperationAction.postCustomerByFilter, (state) => ({
    ...updateFlags(state),
    isLoading: true,
  })),
  on(RentOperationAction.postCustomerByFilterSuccess, (state, { customersList }) => ({
    ...updateFlags(state),
    customersList,
    found: { search: true, found: !!customersList },
  })),

  // save the selected client from the ClientModal into the store
  on(RentOperationAction.setSelectedClient, (state, selectedClient) => ({
    ...updateFlags(state),
    selectedClient,
    found: { search: true, found: !!selectedClient },
  })),

  // set the docClient to dispatch the function to consume the api backend
  on(RentOperationAction.findApprovalValuesList, (state, { docClient, page, size }) => ({
    ...updateFlags(state),
    docClient,
    page,
    size,
  })),

  // set the approval values list into the store
  on(RentOperationAction.setApprovalValuesList, (state, { approvalValuesList }) => ({
    ...updateFlags(state),
    approvalValuesList,
  })),

  // set the approvalValue to search the rent operation values
  on(RentOperationAction.findRentOperationValues, (state, { approvalValue }) => ({
    ...updateFlags(state),
    approvalValue,
  })),

  // set the rent operation values into the store
  on(RentOperationAction.setRentOperationValues, (state, { rentOperationValues }) => ({
    ...updateFlags(state),
    rentOperationValues,
  })),

  // set docNumber parameter which will used to find the legal
  // file data and save it into the store
  on(RentOperationAction.findLegalFileData, (state, { docNumber }) => ({
    ...updateFlags(state),
    docNumber,
  })),

  // set the legal file data
  on(RentOperationAction.setLegalFileData, (state, { legalFile }) => ({
    ...updateFlags(state),
    legalFile,
  })),

  // set the information acta of approval data
  on(RentOperationAction.setInfActApprovalData, (state, { infActApproval }) => ({
    ...updateFlags(state),
    infActApproval,
  })),

  // find the information acta of approval data
  on(RentOperationAction.findInfActApprovalData, (state, { approvalNumber }) => ({
    ...updateFlags(state),
    approvalNumber,
  })),

  // set the approval values data
  on(RentOperationAction.setApprovalValuesData, (state, { approvalValuesData }) => ({
    ...updateFlags(state),
    approvalValuesData,
  })),

  // set url origin
  on(RentOperationAction.findUrlOrigen, (state, { origen }) => ({
    ...updateFlags(state),
    urlOrigen: origen,
  })),

  // find the approval values data
  on(RentOperationAction.findApprovalValuesData, (state, { approvalNumber, page, size }) => ({
    ...updateFlags(state),
    approvalNumber,
    page,
    size,
  })),

  // set the detail values data
  on(RentOperationAction.setDetailValuesData, (state, { detailValues }) => ({
    ...updateFlags(state),
    detailValues,
  })),

  // find the detail values data
  on(RentOperationAction.findDetailValuesData, (state, { approvalNumber }) => ({
    ...updateFlags(state),
    approvalNumber,
  })),

  // set the destinations data
  on(RentOperationAction.setDestinationData, (state, { destinations }) => ({
    ...updateFlags(state),
    destinations,
  })),

  // find the destinations data
  on(RentOperationAction.findDestinationData, (state, { approvalNumber, page, size }) => ({
    ...updateFlags(state),
    approvalNumber,
    page,
    size,
  })),

  // set the warranty data
  on(RentOperationAction.setWarrantyData, (state, { warranty }) => ({
    ...updateFlags(state),
    warranty,
  })),

  // find the warranty data
  on(RentOperationAction.findWarrantyData, (state, { approvalNumber, page, size }) => ({
    ...updateFlags(state),
    approvalNumber,
    page,
    size,
  })),

  // set the observation data
  on(RentOperationAction.setObservationData, (state, { observations }) => ({
    ...updateFlags(state),
    observations,
  })),

  // find the observation data
  on(RentOperationAction.findObservationData, (state, { approvalNumber }) => ({
    ...updateFlags(state),
    approvalNumber,
  })),

  // set the economic group data
  on(RentOperationAction.setEconomicGroupData, (state, { economicGroup }) => ({
    ...updateFlags(state),
    economicGroup,
  })),

  // find the economic group data
  on(RentOperationAction.findEconomicGroupData, (state, { docClient }) => ({
    ...updateFlags(state),
    docClient,
  })),

  // set the legal representative data
  on(RentOperationAction.setLegalRepresentativeData, (state, { legalRepresentative }) => ({
    ...updateFlags(state),
    legalRepresentative,
  })),

  // find the legal representative data
  on(RentOperationAction.findLegalRepresentativeData, (state, { docNumber, page, size }) => ({
    ...updateFlags(state),
    docNumber,
    page,
    size,
  })),

  // set the products by filter data
  on(RentOperationAction.setProductsByFilter, (state, { productsByFilterData }) => ({
    ...updateFlags(state),
    productsByFilterData,
  })),

  // find the products by filter data
  on(RentOperationAction.findProductsByFilter, (state, { code, description }) => ({
    ...updateFlags(state),
    code,
    description,
  })),

  // set selected product by filter data
  on(RentOperationAction.setSelectedProductByFilter, (state, { codeProduct, descripProduct }) => ({
    ...updateFlags(state),
    selectedProductByFilter: {
      codeProduct,
      descripProduct,
    },
  })),

  // set selected resp business data
  on(RentOperationAction.setSelectedRespBusiness, (state, { code, description }) => ({
    ...updateFlags(state),
    selectedRespBusiness: {
      code,
      description,
    },
  })),

  // find resp business data
  on(RentOperationAction.findRespBusiness, (state, { code, description }) => ({
    ...updateFlags(state),
    code,
    description,
  })),

  // set resp business data
  on(RentOperationAction.setRespBusiness, (state, { respBusiness }) => ({
    ...updateFlags(state),
    respBusiness,
  })),

  // find account manager by filter data
  on(RentOperationAction.findAccountManagerByFilter, (state, { code, description }) => ({
    ...updateFlags(state),
    code,
    description,
  })),

  // set account manager by filter data
  on(RentOperationAction.setAccountManagerByFilter, (state, { accountManagerByFilter }) => ({
    ...updateFlags(state),
    accountManagerByFilter,
  })),

  // set selected account manager by filter data
  on(RentOperationAction.setSelectedAccountManagerByFilter, (state, { code, description }) => ({
    ...updateFlags(state),
    selectedAccountManagerByFilter: {
      code,
      description,
    },
  })),

  // find assets by filter data
  on(RentOperationAction.findAssetsByFilter, (state, { code, description }) => ({
    ...updateFlags(state),
    code,
    description,
  })),

  // set assets by filter data
  on(RentOperationAction.setAssetsByFilter, (state, { assetsByFilter }) => ({
    ...updateFlags(state),
    assetsByFilter,
  })),

  // set selected assets by filter data
  on(
    RentOperationAction.setSelectedAssetsByFilter,
    (state, { code, description, permiteAnticipado }) => ({
      ...updateFlags(state),
      selectedAssetsByFilter: {
        code,
        description,
        permiteAnticipado,
      },
    })
  ),

  // set assets condition data
  on(RentOperationAction.setAssetsCondition, (state, { assetsCondition }) => ({
    ...updateFlags(state),
    assetsCondition,
  })),

  // find assets condition data
  on(RentOperationAction.findAssetsCondition, (state) => ({
    ...updateFlags(state),
  })),

  // set selected assets condition data
  on(RentOperationAction.setSelectedAssetsCondition, (state, { code, description }) => ({
    ...updateFlags(state),
    selectedAssetsCondition: { code, description },
  })),

  // set selected channels by filter data
  on(RentOperationAction.setSelectedChannelsByFilter, (state, { code, description }) => ({
    ...updateFlags(state),
    selectedChannelsByFilter: { code, description },
  })),

  // find channels by filter data
  on(RentOperationAction.findChannelsByFilter, (state, { code, description }) => ({
    ...updateFlags(state),
    code,
    description,
  })),

  // set channels by filter data
  on(RentOperationAction.setChannelsByFilter, (state, { channelsByFilter }) => ({
    ...updateFlags(state),
    channelsByFilter,
  })),

  // set funds by filter data
  on(RentOperationAction.setFundsByFilter, (state, { fundsByFilter }) => ({
    ...updateFlags(state),
    fundsByFilter,
  })),

  // find funds by filter data
  on(RentOperationAction.findFundsByFilter, (state, { code, description }) => ({
    ...updateFlags(state),
    code,
    description,
  })),

  // set selected funds by filter data
  on(RentOperationAction.setSelectedFundsByFilter, (state, { code, description }) => ({
    ...updateFlags(state),
    selectedFundsByFilter: {
      code,
      description,
    },
  })),

  // find operation type data
  on(RentOperationAction.findOperationType, (state) => ({
    ...updateFlags(state),
  })),

  // find operation characteristic data
  on(RentOperationAction.findOperationCharacteristic, (state, { page, size, estado }) => ({
    ...updateFlags(state),
    page,
    size,
    estado,
  })),

  // set operation characteristic data
  on(RentOperationAction.setOperationCharacteristic, (state, { operationCharacteristic }) => ({
    ...updateFlags(state),
    operationCharacteristic,
  })),

  // set operation type data
  on(RentOperationAction.setOperationType, (state, { operationType }) => ({
    ...updateFlags(state),
    operationType,
  })),

  // set Rate
  on(RentOperationAction.setRates, (state, { ratesList }) => ({
    ...updateFlags(state),
    ratesList,
  })),

  // find rates data
  on(RentOperationAction.findRates, (state) => ({
    ...updateFlags(state),
  })),

  // set selected rate data
  on(RentOperationAction.setSelectedRate, (state, { selectedRate }) => ({
    ...updateFlags(state),
    selectedRate,
  })),

  // set codeMora list
  on(RentOperationAction.setCodeMora, (state, { codeMoraByFilter }) => ({
    ...updateFlags(state),
    codeMoraByFilter,
  })),

  // find codeMora data
  on(RentOperationAction.findCodeMoraByFilter, (state) => ({
    ...updateFlags(state),
  })),

  // set selected codeMora data
  on(RentOperationAction.setSelectedCodeMora, (state, selectedCodeMoraByFilter) => ({
    ...updateFlags(state),
    selectedCodeMoraByFilter,
  })),
  // set createLease data
  on(RentOperationAction.setCreateLeaseAction, (state, { createLease }) => ({
    ...updateFlags(state),
    createLease,
  })),

  // on error
  on(RentOperationAction.onError, (state, { error }) => ({
    ...updateFlags(state),
    error,
  })),
  // set initialState data
  on(RentOperationAction.resetState, (_) => initialStateGenerator()),

  // set active approve
  on(RentOperationAction.setActiveApproves, (state, { activeApproves }) => ({
    ...updateFlags(state),
    activeApproves,
  })),

  // set arrendatario data
  on(RentOperationAction.setArrendatarioData, (state, { arrendatarios }) => ({
    ...updateFlags(state),
    arrendatarios,
  })),

  // find the observation data
  on(RentOperationAction.findArrendatarioData, (state, { noAprobation, noIdentification }) => ({
    ...updateFlags(state),
    noAprobation,
    noIdentification,
  })),

  // set destiny list
  on(RentOperationAction.setDestinyList, (state, { destinyList }) => ({
    ...updateFlags(state),
    destinyList,
  })),
  // find operation type data
  on(RentOperationAction.findOperationType, (state) => ({
    ...updateFlags(state),
  })),

  on(RentOperationAction.findProjectNameList, (state) => ({
    ...updateFlags(state),
  })),

  on(RentOperationAction.setProjectNameList, (state, { projectNameList }) => ({
    ...updateFlags(state),
    projectNameList,
  })),
  on(RentOperationAction.findUnitsActiveList, (state, { nomProject, tipoActivo, page, size }) => ({
    ...updateFlags(state),
    nomProject,
    tipoActivo,
    page,
    size,
  })),

  on(RentOperationAction.setUnitsActiveList, (state, { unitsActiveList }) => ({
    ...updateFlags(state),
    unitsActiveList,
  })),

  on(
    RentOperationAction.findActivesMarkList,
    (state, { codTipoActivo, codGrupo, codSubGrupo, activoMarca, page, size }) => ({
      ...updateFlags(state),
      codTipoActivo,
      codGrupo,
      codSubGrupo,
      activoMarca,
      page,
      size,
    })
  ),

  on(RentOperationAction.setActivesMarkList, (state, { activesMarkList }) => ({
    ...updateFlags(state),
    activesMarkList,
  })),
  on(RentOperationAction.onResetFlagsError, (state) => ({
    ...updateFlags(state),
    error: null,
  })),

  // set reserve plates id
  on(RentOperationAction.setReservePlatesId, (state, { reserveId }) => ({
    ...updateFlags(state),
    reserveId,
  })),
  /*SET DATA OPERACION RENTA*/
  //set dataCreateLease
  on(RentOperationAction.setDataCreateLease, (state, { dataCreateLease }) => ({
    ...updateFlags(state),
    dataCreateLease,
  })),

  on(RentOperationAction.findOpByContractNumber, (state) => ({
    ...updateFlags(state),
  })),

  on(RentOperationAction.setOpByContractNumberSuccess, (state, { opByContractNumber }) => ({
    ...updateFlags(state),
    opByContractNumber,
  })),

  on(RentOperationAction.onResetFlagsOpByContractNumber, (state) => ({
    ...updateFlags(state),
    opByContractNumber: null,
  })),

  on(RentOperationAction.findCurrentRates, (state) => ({
    ...updateFlags(state),
  })),

  on(RentOperationAction.setCurrentRatesSuccess, (state, { currentRates }) => ({
    ...updateFlags(state),
    currentRates,
  })),

  on(RentOperationAction.setSelectedDataFnlConds, (state, { dataFinancialConditions }) => ({
    ...updateFlags(state),
    dataFinancialConditions,
  })),

  // set rent operation dto
  on(RentOperationAction.setRentOperationDto, (state, { createRentOperationDto }) => ({
    ...updateFlags(state),
    createRentOperationDto,
  })),

  // set create operation dto
  on(RentOperationAction.setCreateOperationDto, (state, { createOperationDto }) => ({
    ...updateFlags(state),
    createOperationDto,
  })),

  // set create lease dto
  on(RentOperationAction.setCreateLeaseDto, (state, { createLeaseDto }) => ({
    ...updateFlags(state),
    createLeaseDto,
  })),

  // findPlatesReserve
  on(RentOperationAction.findPlatesReserve, (state, { plates }) => ({
    ...updateFlags(state),
    reservePlates: [
      ...(state?.reservePlates ?? []),
      {
        plates,
        reserveId: null,
      },
    ],
  })),

  on(RentOperationAction.setReserveId, (state, { reservePlatesData }) => ({
    ...updateFlags(state),
    reservePlates: [...updateReservePlates(state, reservePlatesData)],
  })),

  // find existent data column
  on(RentOperationAction.findExistentDataColumns, (state, { codeActiveType }) => ({
    ...updateFlags(state),
    findExistentDataColumns: codeActiveType,
  })),

  // find non existent data column
  on(RentOperationAction.findNonExistentDataColumns, (state, { codeActiveType }) => ({
    ...updateFlags(state),
    findNonExistentDataColumns: codeActiveType,
  })),

  // set exisent data column
  on(RentOperationAction.setExistentDataColumn, (state, { existentDataColumn }) => ({
    ...updateFlags(state),
    existentDataColumn,
  })),

  // set non exisent data column
  on(RentOperationAction.setNonExistentDataColumn, (state, { nonExistentDataColumn }) => ({
    ...updateFlags(state),
    nonExistentDataColumn,
  })),

  // find actives by plates
  on(RentOperationAction.findActivesByPlates, (state, { plates, monthTerm }) => ({
    ...updateFlags(state),
    plates,
    monthTerm,
  })),

  // set existent assets
  on(RentOperationAction.setExistentAssets, (state, { assets }) => ({
    ...updateFlags(state),
    createLease: {
      ...state.createLease,
      existentAsset: [...[...(state?.createLease?.existentAsset ?? [])], ...assets],
    },
  })),
  on(RentOperationAction.setSelectedAssetsType, (state, { dataAssetTypesSelected }) => ({
    ...updateFlags(state),
    dataAssetTypesSelected,
  })),

  on(RentOperationAction.resetDataAssetTypesSelected, (state) => ({
    ...updateFlags(state),
    dataAssetTypesSelected: null,
  })),

  on(RentOperationAction.resetProductsByFilter, (state) => ({
    ...updateFlags(state),
    productsByFilterData: null,
  })),

  on(RentOperationAction.setSelectedCurrentRate, (state, { selectedCurrentRate }) => ({
    ...updateFlags(state),
    selectedCurrentRate,
  })),

  on(RentOperationAction.setSelectedRadjPeriodicity, (state, { selectedRadjPeriodicity }) => ({
    ...updateFlags(state),
    selectedRadjPeriodicity,
  })),

  on(
    RentOperationAction.setSelectedPaymentPeriodicity,
    (state, { selectedPaymentPeriodicity }) => ({
      ...updateFlags(state),
      selectedPaymentPeriodicity,
    })
  ),

  on(RentOperationAction.setSelectedReadjFormula, (state, { selectedReadjFormula }) => ({
    ...updateFlags(state),
    selectedReadjFormula,
  })),
  // removePlateFromRedux
  on(RentOperationAction.removePlateFromRedux, (state, { plate }) => ({
    ...updateFlags(state),
    reservePlates: removePlate({ plate, state }),
  })),

  on(RentOperationAction.setSelectedAssetToReserve, (state, { dataAssetToReserve }) => ({
    ...updateFlags(state),
    dataAssetToReserve,
  })),

  on(RentOperationAction.resetDataAssetToReserve, (state) => ({
    ...updateFlags(state),
    dataAssetToReserve: null,
  }))
);

export const removePlate = ({
  plate,
  state,
}: {
  plate: string;
  state: RentOperationState;
}): ReservePlates[] => {
  const newPlates = state?.reservePlates ?? ([] as ReservePlates[]);
  return newPlates.map(({ plates, ...obj }) => ({
    ...obj,
    plates: plates.filter((element) => element != plate),
  }));
};

export const updateReservePlates = (
  state: RentOperationState,
  reservePlatesData: ReservePlatesData
): ReservePlates[] => {
  const newReservePlates: any[] = [];
  const currentReserve =
    reservePlatesData?.activosReservados?.listaEstadoActivos?.activos.map(
      ({ placaActivo }) => placaActivo
    ) ?? [];
  const reserveId = reservePlatesData?.activosReservados?.idRegActivosReservados ?? '';
  if (reserveId) {
    if (!state.reservePlates?.length) {
      // if reservePlates state is empty just add this
      newReservePlates.push({
        plates: currentReserve,
        reserveId: reserveId,
      });
      return [...newReservePlates];
    }
    for (const { plates, ...item } of state.reservePlates) {
      // if there is data in store just update the plates with the reserveID
      const match = currentReserve.every((plateId) => plates.includes(plateId));
      if (!match) {
        newReservePlates.push({ plates, ...item });
      }
    }
    newReservePlates.push({
      plates: currentReserve,
      reserveId: reserveId,
    });
  }
  return [...newReservePlates];
};
const updateFlags = (state: RentOperationState): RentOperationState => {
  return {
    ...state,
    error: null,
    isLoading: false,
    found: { search: false, found: false },
  };
};

function Reducer(state: RentOperationState | undefined, action: Action) {
  return featureReducer(state, action);
}

const getListCustomers = (state: RentOperationState) => state.customersList;
const getSelectedClient = (state: RentOperationState) => state.selectedClient;
const getApprovalValuesList = (state: RentOperationState) => state.approvalValuesList;
const getRentOperationValues = (state: RentOperationState) => state.rentOperationValues;
const getLegalFileData = (state: RentOperationState) => state.legalFile;
const getLegalRepresentativeData = (state: RentOperationState) => state.legalRepresentative;
const getApprovalValuesData = (state: RentOperationState) =>
  state ? state.approvalValuesData : null;
const getInfActApprovalData = (state: RentOperationState) => state.infActApproval;
const getDetailValuesData = (state: RentOperationState) => state.detailValues;
const getDestinationsData = (state: RentOperationState) => state?.destinations;
const getWarrantyData = (state: RentOperationState) => state.warranty;
const getObservationsData = (state: RentOperationState) => state.observations;
const getEconomicGroupData = (state: RentOperationState) => state.economicGroup;
const getProducstByFilter = (state: RentOperationState) => state.productsByFilterData;
const getSelectedProductByFilter = (state: RentOperationState) =>
  state ? state.selectedProductByFilter : null;
const getRespBusiness = (state: RentOperationState) => state.respBusiness;
const getSelectedRespBusiness = (state: RentOperationState) => state.selectedRespBusiness;
const getAccountManagerByFilter = (state: RentOperationState) => state.accountManagerByFilter;
const getSelectedAccountManagerByFilter = (state: RentOperationState) =>
  state.selectedAccountManagerByFilter;
const getAssetsByFilter = (state: RentOperationState) => (state ? state.assetsByFilter : null);
const getSelectedAssetsByFilter = (state: RentOperationState) =>
  state ? state.selectedAssetsByFilter : null;
const getAssetsCondition = (state: RentOperationState) => state.assetsCondition;
const getSelectedAssetsCondition = (state: RentOperationState) => state.selectedAssetsCondition;
const getSelectedChannelsByFilter = (state: RentOperationState) => state.selectedChannelsByFilter;
const getChannelsByFilter = (state: RentOperationState) => state.channelsByFilter;
const getFundsByFilter = (state: RentOperationState) => state.fundsByFilter;
const getSelectedFundsByFilter = (state: RentOperationState) => state.selectedFundsByFilter;
const getOperationType = (state: RentOperationState) => state.operationType;
const getOperationCharacteristic = (state: RentOperationState) => state.operationCharacteristic;
const getRates = (state: RentOperationState) => state.ratesList;
const getSelectedRate = (state: RentOperationState) => state.selectedRate;
const getCodeMoraByFilter = (state: RentOperationState) => state.codeMoraByFilter;
const getSelectedCodeMoraByFilter = (state: RentOperationState) => state.selectedCodeMoraByFilter;
const getCreateLease = (state: RentOperationState) => state?.createLease;

const getSelectedAccountManager = (state: RentOperationState) =>
  state.selectedClient?.accountManager;

const getSocietyValidity = (state: RentOperationState) =>
  state ? state.legalFile?.fechaVigenciaSociedad : null;

const getActiveApproves = (state: RentOperationState) => (state ? state.activeApproves : null);

const getArrendatarios = (state: RentOperationState) => (state ? state.arrendatarios : null);

const getNameProjectList = (state: RentOperationState) => (state ? state.projectNameList : null);

const getUnitsActiveList = (state: RentOperationState) => (state ? state.unitsActiveList : null);

const getActivesMarkList = (state: RentOperationState) => (state ? state.activesMarkList : null);

const getError = (state: RentOperationState) => (state ? state.error : null);

const getDestinyList = (state: RentOperationState) => (state ? state.destinyList : null);
const getReserveId = (state: RentOperationState) => (state ? state.reserveId : null);

const getOpByContractNumber = (state: RentOperationState) => state.opByContractNumber;
const getCurrentRates = (state: RentOperationState) => state.currentRates;

const getSelectedDataFnlConds = (state: RentOperationState) => state.dataFinancialConditions;

const getCreateRentOperationDto = (state: RentOperationState) =>
  state ? state.createRentOperationDto : null;

const getCreateOperationDto = (state: RentOperationState) =>
  state ? state.createOperationDto : null;

const getCreateLeaseDto = (state: RentOperationState) => (state ? state.createLeaseDto : null);
const getRentOperationDto = (state: RentOperationState) =>
  state ? state.createRentOperationDto : null;

const getListaFirmantes = (state: RentOperationState) =>
  state ? state?.rentOperationValues?.listaFirmantes : null;

const getReservedId = (state: RentOperationState) => {
  if (!state) {
    return null;
  }

  if (!state.reservePlates) {
    return null;
  }

  const filteredPlates = state.reservePlates.filter(({ reserveId }) => reserveId != null);
  return filteredPlates.length ? filteredPlates.map(({ reserveId }) => reserveId!) : null;
};
const getReservedPlates = (state: RentOperationState) => {
  if (!state) {
    return null;
  }

  if (!state.reservePlates) {
    return null;
  }

  const filteredPlates = state.reservePlates.filter(({ reserveId }) => reserveId != null);

  return filteredPlates.length ? filteredPlates.flatMap(({ plates }) => plates) : null;
};

const getNonExistentDataColumn = (state: RentOperationState) =>
  state ? state?.nonExistentDataColumn : null;
const getExistentDataColumn = (state: RentOperationState) =>
  state ? state?.existentDataColumn : null;

const getSelectedCurrentRate = (state: RentOperationState) => state.selectedCurrentRate;

const getSelectedRadjPeriodicity = (state: RentOperationState) => state.selectedRadjPeriodicity;

const getSelectedPaymentPeriodicity = (state: RentOperationState) =>
  state.selectedPaymentPeriodicity;

const getSelectedReadjFormula = (state: RentOperationState) => state.selectedReadjFormula;

const getUrlOrigen = (state: RentOperationState) => state.urlOrigen;

export const RentOperationReducer = {
  Reducer,
  getReservedId,
  getNonExistentDataColumn,
  getExistentDataColumn,
  getReservedPlates,
  getListaFirmantes,
  getRentOperationDto,
  getCreateLeaseDto,
  getCreateOperationDto,
  getCreateRentOperationDto,
  getReserveId,
  getListCustomers,
  getSelectedClient,
  getApprovalValuesList,
  getRentOperationValues,
  getLegalFileData,
  getLegalRepresentativeData,
  getInfActApprovalData,
  getApprovalValuesData,
  getDetailValuesData,
  getDestinationsData,
  getWarrantyData,
  getObservationsData,
  getEconomicGroupData,
  getProducstByFilter,
  getSelectedProductByFilter,
  getRespBusiness,
  getSelectedRespBusiness,
  getAccountManagerByFilter,
  getSelectedAccountManagerByFilter,
  getAssetsByFilter,
  getSelectedAssetsByFilter,
  getAssetsCondition,
  getSelectedAssetsCondition,
  getChannelsByFilter,
  getSelectedChannelsByFilter,
  getFundsByFilter,
  getSelectedFundsByFilter,
  getOperationType,
  getOperationCharacteristic,
  getRates,
  getSelectedRate,
  getCodeMoraByFilter,
  getSelectedCodeMoraByFilter,
  getCreateLease,
  getSelectedAccountManager,
  getSocietyValidity,
  getActiveApproves,
  getArrendatarios,
  getNameProjectList,
  getUnitsActiveList,
  getActivesMarkList,
  getError,
  getDestinyList,
  getOpByContractNumber,
  getCurrentRates,
  getSelectedDataFnlConds,
  getSelectedCurrentRate,
  getSelectedRadjPeriodicity,
  getSelectedPaymentPeriodicity,
  getSelectedReadjFormula,
  getUrlOrigen,
};
export { RentOperationState };
