import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'bcs-text-box',
  templateUrl: './bcs-text-box.component.html',
  styleUrls: ['./bcs-text-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DecimalPipe],
})
export class BcsTextBoxComponent {
  @Input() Label: string = '';
  @Input() Icon: string = '';
  @Input() IconRight: string = '';
  @Input() HelpText: string = '';
  @Input() Id: number | string = 1;
  @Input() Type: input = '';
  @Input() formatPercentage: boolean = false;
  @Input() IconPosition: 'Left' | 'Right' = 'Right';
  @Input() InputFormControl: any = new FormControl(null, []);
  @Input() submited: boolean = false;
  @Input() valores: any = '';
  @Input() Value!: any;
  @Input() disabled: boolean = false;
  @Output() Consult = new EventEmitter<void>();
  @Output() input = new EventEmitter<any>();
  @Input() inputFormControlName!: string;
  @Input() uppercase!: any;
  @Input() negativeValue = false;
  @Input() isRequired = false;
  @Input() readonly = false;
  @Input() space: boolean = false;
  state: boolean = false;
  optionCurrencyMask: any = { suffix: '', prefix: '', align: 'left' };

  constructor(private decimalPipe: DecimalPipe) {}

  onChange(): void {
    if (this.uppercase) {
      this.InputFormControl.setValue(this.InputFormControl.value.toUpperCase());
    }
  }

  ngOnInit(): void {
    if (this.valores !== '') {
      this.InputFormControl = new FormControl(this.valores, []);
    }
  }

  onInput(): void {
    this.onChange();
    this.input.emit();
  }

  modalConsult() {
    if (this.Consult) {
      this.Consult.emit();
    }
  }

  get control() {
    return this.InputFormControl;
  }

  get isError(): boolean {
    return (
      ((this.submited == !this.state || this.control?.touched == !this.state) &&
        this.control?.invalid) ??
      false
    );
  }

  get errorText(): string {
    return this.makeMessage();
  }

  makeMessage() {
    const errors = this.control.errors;
    if (errors?.required === !this.state) {
      return 'El campo es requerido';
    }
    if (errors?.minlength) {
      return `El campo ${this.Label} debe tener mínimo ${errors.minlength.requiredLength} caracteres`;
    }
    if (errors?.maxlength) {
      return `El campo ${this.Label} debe tener máximo ${errors.maxlength.requiredLength} caracteres`;
    }
    if (errors?.max) {
      return this.getLabelBasedMessage(errors.max, 'max');
    }
    if (errors?.min) {
      return this.getLabelBasedMessage(errors.min, 'min');
    }
    if (errors?.email) {
      return `El campo ${this.Label} debe tener un formato válido de correo electrónico`;
    }
    if (errors) {
      return `El campo ${this.Label} contiene errores.`;
    }
    return '';
  }

  getLabelBasedMessage(error: any, type: any) {
    const isLabelPercentage = this.Label.includes('%');
    const comparisonType = type === 'max' ? 'menor' : 'mayor';
    const errorMessage = error[type];

    if (isLabelPercentage) {
      return `El valor debe ser ${comparisonType} o igual a ${errorMessage}%`;
    } else {
      return `El campo debe ser ${comparisonType} o igual a ${errorMessage}`;
    }
  }
}
type input =
  | 'number'
  | 'text'
  | 'date'
  | 'email'
  | 'file'
  | 'button'
  | 'submit'
  | 'password'
  | 'format'
  | 'money'
  | '';
